import Api from "./Api";

export const getAllContract = async (params) =>
  Api().get(`contract`, { params });

export const setContractDelete = async (payload) =>
  Api().post(`contract/delete`, payload);

export const getSingleContract = async (id) =>
  Api().get(`contract/single/${id}`);

export const saveContract = async (payload) =>
  Api().post(`contract/save`, payload);

export const GetContractTransactions = async (contract_id) =>
  Api().get(`/contract/transactions/${contract_id}`);

export const getAllMilestones = async (params) =>
  Api().get(`contract/milestones`, { params });

export const SaveContractMileStones = async (payload) =>
  Api().post(`/contract/milestones`, payload);

export const getAllBumpers = async (params) =>
  Api().get(`contract/bumpers`, { params });

export const SaveContractBumper = async (payload) =>
  Api().post(`/contract/bumpers`, payload);

export const getAvailableTrl = async (payload) =>
  Api().get(`contract/available-trl`, { params: payload });

export const getAvailableDateRange = async ({ movie_id }) =>
  Api().get(`contract/available-date-range`, { params: movie_id });

export const GetContractLogs = async (contract_id) =>
  Api().get(`contract/logs/${contract_id}`);

export const GetAcquiredTrl = async (payload) =>
  Api().post(`contract/acquired-trl`, payload);

export const GetSelectList = async (payload) =>
  Api().get(`contract/select-list`, payload);
